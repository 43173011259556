import { DropDownWithoutIcon } from "global/components/DropDownWithoutIcon"

import dataRetention from '../../../data/policies/DataRetention.json';


export const DataRetention = () => {

    const title = dataRetention.title

    return (
        <DropDownWithoutIcon title={title}>
            <div className="privacy-grid-information">
                {   
                 dataRetention&&
                    (
						<>
                            {
                                dataRetention.content.map((content, index)=> (
                                    
                                    <span key={index}>
                                        <p>{content.text}</p>
                                    </span>
                                        
                            

                                ))
                            }

                        </>
                    )


                }
            </div>
           

            
        </DropDownWithoutIcon>
    )


}