import { ListItem } from "@mui/material";
import { PropsWithChildren } from "react";

import "./styles/SdgItem.css";

  
interface Props {
    isLegible: boolean;
    index: number;
}



export const SdgItem = ({isLegible, index}: PropsWithChildren<Props>) => {
    return (
        <>  
            <ListItem disablePadding key={index} className="image-sdg" sx={{ width: 1/2 }}>
                    {
                        isLegible &&
                        <img className="enabled-img" src={`/images/shared/sdg/${index}.png`}
                            alt="SDG imagen"/>

                    }
                    {
                        !isLegible && 

                        <img className="disabled-img" src={`/images/shared/sdg/${index}.png`}
                            alt="SDG imagen"/>
                    }
                   
            </ListItem >

        </>
    );

};
