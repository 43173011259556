import "./styles/TechnologyProfile.css";
import { SDGList } from "global/components/SDGList";
import { BackButtonArrow } from "global/components/BackButtonArrow";

import Team from "./components/Team";
import { useParams } from "react-router-dom";
import SQLPaths from "global/utils/sqlPaths";
import useSQL from "hooks/useSQL";
import { useState, useEffect, useContext } from 'react';

import ContactForm from "global/components/ContactForm";
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";
import useGetFourthUrlParam from "hooks/useGetFourthUrlParam";
import { UserContext } from "auth/login/container/UserContext";

interface TechnologyUrlParams {
	university: string;
	idTechnology: string;
}


const TechnologyProfile = () => {
	const { university, idTechnology } = useParams<TechnologyUrlParams>();

	const [sdgItems, setSdgItems] = useState(new Array(17).fill(false));

	const { response: technology, fetching } = useSQL(SQLPaths.technology, university, idTechnology);

	const Login = useContext(UserContext);

	useEffect(() => {
		if (!fetching) {
			const array_aux = new Array(17).fill(false);
			technology?.sdg?.forEach((sdg: number) => {
				array_aux[sdg - 1] = true;
			});
			setSdgItems(array_aux);
		}

	}, [fetching]);
	const currentLocationWitdDomain = window.location.href;

	const origin = useGetFirstUrlParam();

	const currentOrigin = useGetFourthUrlParam();
	return (
		<>
			{!fetching && <SDGList sdg={sdgItems} />}


			<section className="container-result-search-technology">
				<div className="column">
					<div className="inline-arrow">
						{!fetching && <BackButtonArrow />}
						{!fetching && (<p className="title-tecnologia">{technology.nombre}</p>)}
					</div>

					<hr />
					<br />
					{!fetching && technology.descripcion && (
						<div className="technology-item-container">
							<p className="title-item-tecnologia">Descripción:</p>
							<p className="text-item-tecnologia">{technology.descripcion}</p>
						</div>
					)}
					{!fetching && technology.beneficios.length > 0 && (
						<div className="technology-item-container">
							<p className="title-item-tecnologia">Beneficios:</p>
							{technology.beneficios.map(({ nombre }: { nombre: string }, index: number) => (
								<p key={nombre + index} className="text-item-tecnologia">
									• {nombre}
								</p>
							))}
						</div>
					)}
					{!fetching && technology.aplicaciones.length > 0 && (
						<div className="technology-item-container">
							<p className="title-item-tecnologia">Aplicaciones:</p>
							{technology.aplicaciones.map(({ nombre }: { nombre: string }, index: number) => (
								<p key={nombre + index} className="text-item-tecnologia">
									• {nombre}
								</p>
							))}
						</div>
					)}

					{!fetching &&
						(technology.estado_pi ||
							technology.nro_solicitud ||
							technology.pais_solicitud ||
							technology.anio_solicitud) && (
							<div className="technology-item-container">
								<p className="title-item-tecnologia">Propiedad Intelectual:</p>
								{technology.nro_solicitud && (
									<p className="text-item-tecnologia">
										• Nro. Solicitud/Concesión: {technology.nro_solicitud}
									</p>
								)}
								{technology.nro_solicitud && technology.pais_solicitud && (
									<p className="text-item-tecnologia"> • Pais: {technology.pais_solicitud}</p>
								)}
								{technology.nro_solicitud && technology.anio_solicitud && (
									<p className="text-item-tecnologia"> • Año: {technology.anio_solicitud}</p>
								)}
								{technology.estado_pi && (
									<p className="text-item-tecnologia"> • Estado: {technology.estado_pi}</p>
								)}
							</div>
						)}
					{!fetching && technology.industrias && (
						<div className="technology-item-container">
							<p className="title-item-tecnologia">Industrias:</p>
							<p className="text-item-tecnologia">• {technology.industrias}</p>
						</div>
					)}
					{!fetching && technology.equipo.length > 0 && <Team {...technology} />}
					{!fetching && technology.trl && (
						<div className="technology-item-container">
							<p className="title-item-tecnologia">TRL:</p>
							<p className="text-item-tecnologia">{technology.trl}</p>
						</div>
					)}
					{!fetching && technology.oportunidad && (
						<div className="technology-item-container">
							<p className="title-item-tecnologia">Oportunidad:</p>
							<p className="text-item-tecnologia">{technology.oportunidad}</p>
						</div>
					)}
				</div>
				{Login.user &&
					<div className="content-right-investigacion-contact">
						<div className="content-contactar-articulo">
							<div className="display-block-div">
								<p className="title-lineas-investigacion">¿Quieres saber más sobre la tecnología?</p>
								<ContactForm
									currentLocation={origin}
									origin={currentOrigin}
									dimension="Tecnologías"
									location={currentLocationWitdDomain}
									openModalElementClassName="btn-contactar-articulo"
									openModalElementText="Contacto"
									openModalElementAsButton={true}
								/>
							</div>

						</div>
					</div>
				}
			</section>
		</>
	);
};

export default TechnologyProfile;
