import { DropDownWithoutIcon } from "global/components/DropDownWithoutIcon"


import dataRecopilations from '../../../data/policies/RecopilationsAndPersonalData.json';


export const RecopilationsAndPersonalData = () => {


    const title = dataRecopilations.title


    return (
        <DropDownWithoutIcon title={title}>
            <div className="privacy-grid-informatio">
                {   
                    dataRecopilations &&
                    (
						<>
                            {
                                dataRecopilations.content.map((content, index)=> (
                                    
                                    <span key={index}>
                                        <p className="subtitle-politica" key={index}>{content.subcontent}</p>
                                        <p>{content.text}</p>
                                        <ul>
                                        {
                                            content.subsubcontent?.map((subsubcontent, index) => (
                                           
                                                    <li  key={subsubcontent + index.toString()}>
                                                         {subsubcontent.description}
                                                    </li>
                                                
                                                
                                            ))
                                        }
                                        </ul>

                                        <p>{content.ps}</p>
                        
                                    </span>
                                        
                            

                                ))
                            }

                        </>
                    )


                }
            </div>


            
        </DropDownWithoutIcon>
    )


}